import i18n from '@/locales'
import { api } from '@/main'
import _ from 'lodash'
import {
  SystemClient,
  checkApiResponse,
  InitSystemClient,
  SearchSystemsClient,
  SystemClientInteractions,
  SystemOtherSourceClient,
  SystemSubjectClient,
  SystemThirdPartyClient,
  SystemThirdPartySubjectsClient,
} from '@/api/clients'

export default {
  async fetchSystems(context, payload) {
    const systemClient = new SystemClient()
    const result = await systemClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEMS', result.data)
    }
  },

  async orderingSystems(context, payload) {
    const systemClient = new SystemClient()
    const result = await systemClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEMS', result.data)
    }
  },

  async fetchAllSystemData(context, payload) {
    const systemClient = new InitSystemClient()
    const result = await systemClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_INIT_SYSTEM_DATA', result.data)
    }
    await context.dispatch('company/fetchThirdParties', { company_id: payload.company_id }, { root: true })
  },

  async fetchSystem(context, payload) {
    // Получаем систему
    const systemClient = new SystemClient()
    const result = await systemClient.fetch(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM', result.data)
    }
  },

  async addSystem(context, payload) {
    // Добавляем систему
    const addSystemClient = new SystemClient()
    const result = await addSystemClient.create(payload)
    if (result.data?.id) {
      context.commit('ADD_SYSTEM', result.data)
      if (['Analyst', 'Client'].includes(context.rootState.user.user?.status)) {
          context.state.editable_systems.push(result.data.id)
      }
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.system_create'), { root: true })
      await context.dispatch('company/fetchCompanyTags', {company_id: payload.company_id}, { root: true })
      return result
    }
    // Если не удается создать систему
    if (result.data?.freemium) {
      // Превышен лимит анкет для демо компании
      context.commit(
        'BANNER',
        {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail'),
        },
        { root: true },
      )
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.systems.creating_error'), { root: true })
    }
  },

  async deleteSystem(context, payload) {
    // Удаляет систему
    const systemClient = new SystemClient()
    const result = await systemClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM', payload.id)
    }
  },

  updateSystem: _.debounce(async (context, payload) => {
    // Обновляем поле системы
    let fields = {}
    fields[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_SYSTEM', { id: payload.form.id, fields, getters: context.getters })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    const systemClient = new SystemClient()
    const result = await systemClient.update({
      // payload.form.id, { ...fields, percent: context.getters.percent }
      id: payload.form.id,
      company_id: payload.form.company,
      fields: { ...fields, id: payload.form.id, percent: context.getters.percent },
    })
    await checkApiResponse(result, context)
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchSystem', { company: payload.form.company, system_id: payload.form.id })
    }
  }, 500),

  async updateSystemFields(context, payload) {
    // Обновляем поля системы
    let fields = {}
    fields = payload.value
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    const systemClient = new SystemClient()
    const result = await systemClient.update({
      ...payload,
      id: payload.system_id,
      fields: fields,
    })
    if (await checkApiResponse(result, context)) {
      // Записываем изменения в store, чтобы у нас были правильные проценты
      context.commit('UPDATE_SYSTEM', { id: payload.system_id, fields, getters: context.getters })
    }
  },

  async deleteSystemDocument(context, payload) {
    // Обновляем поле анкеты
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    const systemClient = new SystemClient()
    const result = await systemClient.update({
      id: payload.form.id,
      company_id: payload.form.company,
      fields: { ...fields, percent: context.getters.percent },
    })

    if (await checkApiResponse(result, context)) {
      //Удаляем документ, если смогли отвязать от системы
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
      context.commit('UPDATE_SYSTEM', { id: payload.form.id, fields, getters: context.getters })
    }
  },

  // Другие источники
  async fetchSourceOthers(context, payload) {
    // Получаем другие источники
    const fetchOtherSourceClient = new SystemOtherSourceClient()
    const result = await fetchOtherSourceClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_SOURCE_OTHERS', result.data)
    }
  },

  async addSourceOther(context, payload) {
    // Добавляем другой источник
    const addOtherSourceClient = new SystemOtherSourceClient()
    const result = await addOtherSourceClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_SOURCE_OTHER', result.data)
    }
    await context.dispatch('fetchSourceOthers', { ...payload, system: payload.system })
  },

  async deleteSourceOther(context, payload) {
    // Удаляет другой источник
    const deleteOtherSourceClient = new SystemOtherSourceClient()
    const result = await deleteOtherSourceClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_SOURCE_OTHER', payload.id)
    }
    await context.dispatch('fetchSourceOthers', { ...payload, system_id: payload.system })
  },

  // Субъекты
  async fetchSystemSubjects(context, payload) {
    const fetchSubjectClient = new SystemSubjectClient()
    const result = await fetchSubjectClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_SUBJECTS', result.data)
    }
  },

  async addSystemSubject(context, payload) {
    // Добавляем субъект системы
    const systemSubjectClient = new SystemSubjectClient()
    const result = await systemSubjectClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_SUBJECT', result.data)
    }
  },

  async deleteSystemSubject(context, payload) {
    // Удаляет субъект системы
    const deleteSubjectClient = new SystemSubjectClient()
    const result = await deleteSubjectClient.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_SUBJECT', payload.id)
    }
    await context.dispatch('fetchSystemThirdPartySubject', payload)
  },

  async updateSystemSubject(context, payload) {
    let fields = {}
    fields[payload.field_name] = payload.value
    fields['id'] = payload.id
    const systemSubjectClient = new SystemSubjectClient()
    const result = await systemSubjectClient.update({
      ...payload,
      id: fields.id,
      fields: fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_SUBJECT', result.data)
    }
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('getSystem', { company: payload.form.company, system_id: payload.form.id })
    }
  },

  // Третьи лица
  async fetchSystemThirdParties(context, payload) {
    const fetchSysThirdParties = new SystemThirdPartyClient(payload.company, payload.system)
    const result = await fetchSysThirdParties.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_THIRD_PARTIES', result.data)
    }
  },

  async addSystemThirdParty(context, payload) {
    const systemThirdPartyClient = new SystemThirdPartyClient()
    const result = await systemThirdPartyClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_THIRD_PARTY', result.data)
    }
    await context.dispatch('company/fetchCompanyThirdPartiesDetails', payload.company_id, { root: true })
    return result
  },

  async deleteSystemThirdParty(context, payload) {
    const deleteThirdParty = new SystemThirdPartyClient()
    const result = await deleteThirdParty.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_THIRD_PARTY', payload.id)
    }
  },

  updateSystemThirdParty: _.debounce(async (context, payload) => {
    // Обновляем систему
    let fields = {}
    fields[payload.field_name] = payload.value
    const systemThirdPartyClient = new SystemThirdPartyClient()
    const result = await systemThirdPartyClient.update({
      ...payload,
      id: payload.form.id,
      fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_THIRD_PARTY', { ...fields, id: payload.form.id })
    }
  }, 1000),

  async deleteDocumentThirdParty(context, payload) {
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    const systemThirdPartyClient = new SystemThirdPartyClient()
    const result = await systemThirdPartyClient.update({
      ...payload,
      fields,
    })
    if (await checkApiResponse(result, context)) {
      //Удаляем документ, если смогли отвязать
      context.commit('UPDATE_SYSTEM_THIRD_PARTY', { ...fields, id: payload.id })
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async fetchSystemThirdPartySubject(context, payload) {
    const fetchThirdPartySubjectClient = new SystemThirdPartySubjectsClient()
    const result = await fetchThirdPartySubjectClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_THIRD_PARTY_SUBJECTS', result.data)
    }
  },

  async addSystemThirdPartySubject(context, payload) {
    const addThirdPartySubject = new SystemThirdPartySubjectsClient()
    const result = await addThirdPartySubject.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_THIRD_PARTY_SUBJECT', result.data)
    }
  },

  async deleteSystemThirdPartySubject(context, payload) {
    const delSystemThirdPartySubj = new SystemThirdPartySubjectsClient()
    const result = await delSystemThirdPartySubj.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_THIRD_PARTY_SUBJECT', payload.id)
    }
  },

  updateSystemThirdPartySubject: _.debounce(async (context, payload) => {
    // Обновляем третьи лица в системе
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateThirdPartySubject = new SystemThirdPartySubjectsClient()
    const result = await updateThirdPartySubject.update({
      ...payload,
      id: payload.form.id,
      fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_THIRD_PARTY_SUBJECT', result.data)
    }
  }, 100),

  async searchSystems(context, payload) {
    const searchSystem = new SearchSystemsClient()
    const result = await searchSystem.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('FILTER_SYSTEMS', result.data)
    }
  },

  async filteredTagsSystems(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/systems/?tags=${payload.tags}&limit=${payload.limit}`,
      context,
      {},
      'FILTER_SYSTEMS',
      i18n.global.t('company.systems.loading_systems_error'),
    )
  },
  // Взаимодействие с другими информационными системами
  async fetchSystemInteractions(context, payload) {
    const systemInteractionClient = new SystemClientInteractions()
    const result = await systemInteractionClient.fetchAll(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_INTERACTIONS', result.data)
    }
  },

  async addSystemInteraction(context, payload) {
    const systemClientInteractions = new SystemClientInteractions()
    const result = await systemClientInteractions.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_INTERACTION', result.data)
    }
  },

  async deleteSystemInteraction(context, payload) {
    const systemClientInteractions = new SystemClientInteractions()
    const result = await systemClientInteractions.delete(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_INTERACTION', payload.id)
    }
  },

  updateSystemInteraction: _.debounce(async (context, payload) => {
    // Обновляем данные
    let fields = {}
    fields[payload.field_name] = payload.value
    const systemClientInteractions = new SystemClientInteractions()
    const result = await systemClientInteractions.update({
      ...payload,
      fields,
    })
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_INTERACTION', { ...fields, id: payload.id })
    }
    await context.dispatch('fetchSystemInteractions', payload)
  }, 50),
}
